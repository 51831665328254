<template>
  <div>
    <div class="page-sub-title">
      小程序授权
    </div>
    <div class="mb-30" v-if="!form.authorizedAppId">
      <el-button type="primary" size="small" @click="toAuth">去授权</el-button>
    </div>
    <div class="mb-30" v-else-if="form.authorizedAppId !== form.appId">
      <i class="icon-fail mr-10"></i><span class="mr-10">授权关联失败，与开户的APPID不一致，请确认并重新授权</span>
      <el-button type="primary" size="small" @click="toAuth">重新授权</el-button>
    </div>
    <div class="mb-30" v-else>
      <i class="icon-success mr-10"></i><span>授权关联成功，与开户的APPID一致</span>
    </div>
    <div v-if="form.authorizedAppId && form.authorizedAppId === form.appId">
      <div class="page-sub-title">
        域名配置
      </div>
      <div class="flex-form flex flex-wrap mb-30">
        <div class="form-item flex">
          <div class="form-label pt-5">
            request合法域名
          </div>
          <el-input class="flex-1" v-model="form.requestDomainList" size="small" type="textarea" placeholder="请填写request合法域名">
          </el-input>
        </div>
        <div class="form-item flex">
          <div class="form-label pt-5">
            socket合法域名
          </div>
          <el-input class="flex-1" v-model="form.wsRequestDomainList" size="small" type="textarea" placeholder="请填写socket合法域名">
          </el-input>
        </div>
        <div class="form-item flex">
          <div class="form-label pt-5">
            uploadFile合法域名
          </div>
          <el-input class="flex-1" v-model="form.uploadDomainList" size="small" type="textarea" placeholder="请填写uploadFile合法域名">
          </el-input>
        </div>
        <div class="form-item flex">
          <div class="form-label pt-5">
            downloadFile合法域名
          </div>
          <el-input class="flex-1" v-model="form.downloadDomainList" size="small" type="textarea" placeholder="请填写downloadFile合法域名">
          </el-input>
        </div>
        <div class="form-item flex">
          <div class="form-label pt-5">
            业务域名
          </div>
          <el-input class="flex-1" v-model="form.webviewDomainList" size="small" type="textarea" placeholder="请填写业务域名"></el-input>
        </div>
        <div class="form-item flex">
          <el-button class="self-end" type="primary" size="small" @click="saveDomain">保存配置</el-button>
        </div>
      </div>
      <div class="page-sub-title flex flex-center">
        <div class="flex-1">版本信息</div>
      </div>
      <div class="flex-form version-content mb-30">
        <div class="version-item">
          <div class="flex flex-align-center mb-10">
            <span class="icon-version"></span>
            <span class="version">线上版本</span>
          </div>
          <div class="flex flex-center mb-10">
            <span><i class="point"></i>版本号：{{form.releaseInfo.releaseVersion}}</span>
            <span class="flex-1 ml-30"><i class="point"></i>发布时间：{{form.releaseInfo.timeFmt}}</span>
            <el-button class="width88" type="primary" size="small" @click="handleCommand('revert')">版本回退</el-button>
          </div>
          <div><i class="point"></i>版本描述：{{form.releaseInfo.releaseDesc}}</div>
        </div>
        <div class="version-item">
          <div class="flex flex-align-center mb-10">
            <span class="icon-version"></span>
            <span class="version">审核版本</span>
            <span class="ml-20" :class="'audit-status' + form.latestAuditStatus.status">{{auditStatusMap[form.latestAuditStatus.status]}}</span>
            <el-button class="ml-20" type="text" @click="showReason" v-if="form.latestAuditStatus.status == 1">查看拒绝原因</el-button>
          </div>
          <div class="flex flex-center mb-10">
            <span><i class="point"></i>版本号：{{form.latestAuditStatus.userVersion}}</span>
            <span class="ml-30"><i class="point"></i>提审时间：{{form.latestAuditStatus.timeFmt}}</span>
            <span class="flex-1 ml-30"><i class="point"></i>审核ID：{{form.latestAuditStatus.auditId}}</span>
            <el-button class="border-btn width88" plain size="small" @click="undoCodeAudit" v-if="form.latestAuditStatus.userVersion && form.latestAuditStatus.status == 2">审核撤回</el-button>
            <el-button class="width88" type="primary" size="small" @click="release" v-if="form.latestAuditStatus.userVersion && form.latestAuditStatus.status == 0">发布</el-button>
          </div>
          <div><i class="point"></i>版本描述：{{form.latestAuditStatus.userDesc}}</div>
        </div>
        <div class="version-item">
          <div class="flex flex-align-center mb-10">
            <span class="icon-version"></span>
            <span class="version">体验版本</span>
          </div>
          <div class="flex flex-center mb-10">
            <span><i class="point"></i>版本号：{{form.expInfo.expVersion}}</span>
            <span class="flex-1 ml-30"><i class="point"></i>提交体验版的时间：{{form.expInfo.timeFmt}}</span>
            <el-button class="border-btn width88" plain size="small" @click="handleCommand('upload')">上传</el-button>
            <el-button class="width88" type="primary" size="small" @click="submitAudit" v-if="form.expInfo.expVersion">提交审核</el-button>
          </div>
          <div class="mb-10"><i class="point"></i>版本描述：{{form.expInfo.expDesc}}</div>
          <el-image
            style="width: 130px; height: 130px"
            v-if="form.expInfo.url"
            :src="form.expInfo.url" 
            :preview-src-list="[form.expInfo.url]">
          </el-image>
        </div>
      </div>
      <div class="page-sub-title flex flex-center">
        <div class="flex-1">体验成员</div>
      </div>
      <div>
        <el-button type="primary" size="small" @click="handleCommand('tester')">+ 绑定成员</el-button>
      </div>
    </div>

    <!-- 上传弹窗 -->
    <code-upload-dialog :visible.sync="uploadVisible" :id="form.id" @reload="getData" :type="type"></code-upload-dialog>

    <!-- 版本回退弹窗 -->
    <code-revert-dialog :visible.sync="revertVisible" :id="form.id" @reload="getData"></code-revert-dialog>

    <!-- 绑定体验成员弹窗 -->
    <bind-tester-dialog :visible.sync="testerVisible" :id="form.id"></bind-tester-dialog>

    <!-- 拒绝原因弹窗 -->
    <reason-dialog :visible.sync="reasonVisible" :reason="form.latestAuditStatus.reason" :screenShots="form.latestAuditStatus.screenShotUrlList"></reason-dialog>
  </div>
</template>

<script>
import codeUploadDialog from './code-upload-dialog'
import codeRevertDialog from './code-revert-dialog'
import bindTesterDialog from './bind-tester-dialog'
import reasonDialog from './reason-dialog'
import {
  get_minip_auth_and_version,
  update_minip_domain,
  undo_code_audit,
  release_minip,
  submit_audit
} from '@/api/miniprogram'
import moment from 'moment'
import { ip_url } from "@/config";

export default {
  components: {
    codeUploadDialog,
    codeRevertDialog,
    bindTesterDialog,
    reasonDialog,
  },
  props: {
    type: {
      type: String
    }
  },
  data() {
    return {
      form: {
        releaseInfo: {},
        latestAuditStatus: {},
        expInfo: {}
      },
      uploadVisible: false,
      revertVisible: false,
      testerVisible: false,
      reasonVisible: false,
      auditStatusMap: {
        0: '审核通过',
        1: '审核拒绝',
        2: '审核中',
        3: '审核撤回'
      }
    }
  },
  computed: {
  },
  watch: {
  },
  mounted() {
    if (this.$route.query.id) {
      this.form.id = this.$route.query.id
      this.getData()
    }
  },
  methods: {
    toAuth() {
      window.open(ip_url + '/test?id=' + this.form.id, '_blank')
    },
    getData() {
      get_minip_auth_and_version({
        id: this.form.id
      }).then(res => {
        this.form = {
          ...res,
          releaseInfo: res.releaseInfo ? {
            ...res.releaseInfo,
            timeFmt: res.releaseInfo.releaseTime ? moment.unix(res.releaseInfo.releaseTime).format('YYYY-MM-DD HH:mm:ss') : ''
          } : {},
          latestAuditStatus: res.latestAuditStatus ? {
            ...res.latestAuditStatus,
            timeFmt: res.latestAuditStatus.submitAuditTime ? moment.unix(res.latestAuditStatus.submitAuditTime).format('YYYY-MM-DD HH:mm:ss') : ''
          } : {},
          expInfo: res.expInfo ? {
            ...res.expInfo,
            timeFmt: res.expInfo.expTime ? moment.unix(res.expInfo.expTime).format('YYYY-MM-DD HH:mm:ss') : ''
          } : {},
          requestDomainList: (res.requestDomainList || []).join(','),
          wsRequestDomainList: (res.wsRequestDomainList || []).join(','),
          uploadDomainList: (res.uploadDomainList || []).join(','),
          downloadDomainList: (res.downloadDomainList || []).join(','),
          webviewDomainList: (res.webviewDomainList || []).join(','),
        }
        console.log(this.form)
      })
    },
    saveDomain() {
      update_minip_domain({
        id: this.form.id,
        action: 'set',
        requestDomainList: this.form.requestDomainList ? this.form.requestDomainList.split(',') : [],
        wsRequestDomainList: this.form.wsRequestDomainList ? this.form.wsRequestDomainList.split(',') : [],
        uploadDomainList: this.form.uploadDomainList ? this.form.uploadDomainList.split(',') : [],
        downloadDomainList: this.form.downloadDomainList ? this.form.downloadDomainList.split(',') : [],
        webviewDomainList: this.form.webviewDomainList ? this.form.webviewDomainList.split(',') : [],
      }).then(() => {
        this.$message.success('保存成功')
        this.getData()
      })
    },
    submitAudit() {
      submit_audit({
        id: this.form.id,
        version_desc: this.form.expInfo.expDesc,
        item_list: [],
        feedback_info: '',
        feedback_stuff: '',
        preview_info: {
          video_id_list: [],
          pic_id_list: []
        },
      }).then(() => {
        this.$message.success('提交审核成功')
        this.getData()
      })
    },
    undoCodeAudit() {
      this.$confirm('请确认是否撤回审核', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        customClass: 'dialog-class',
        center: true
      }).then(() => {
        undo_code_audit({
          id: this.form.id
        }).then(() => {
          this.$message.success('审核撤回成功')
          this.getData()
        })
      })
    },
    release() {
      release_minip({
        id: this.form.id
      }).then(() => {
        this.$message.success('发布成功')
        this.getData()
      })
    },
    handleCommand(val) {
      switch (val) {
        case 'upload':
          this.uploadVisible = true
          break
        case 'revert':
          this.revertVisible = true
          break
        case 'tester':
          this.testerVisible = true
          break
        default:
          break
      }
    },
    showReason() {
      this.reasonVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
.hotel-title{
  color: #5390FF;
  font-size: 18px;
  margin-bottom: 20px;
}
.flex-form{
  max-width: 1000px;
}
.form-item{
  margin-bottom: 16px;
  width: 45%;
  margin-right: 5%;
  .must{
    color: #E14B38
  }
}
.form-label{
  padding-right: 10px;
  text-align: right;
  width: 150px;
}
.width88{
  width: 88px;
}
.icon-success{
  background: url('../../../../assets/icons/icon-minip-success.png') no-repeat center center/100% 100%;
  display: inline-block;
  vertical-align: -5px;
  width: 20px;
  height: 20px;
}
.icon-fail{
  background: url('../../../../assets/icons/icon-minip-fail.png') no-repeat center center/100% 100%;
  display: inline-block;
  vertical-align: -5px;
  width: 20px;
  height: 20px;
}
.version-content{
  background: #FAFBFE;
  padding: 4px 24px;
  .version-item{
    padding: 20px 0;
    & + .version-item{
      border-top: 1px solid #E8EEF7;
    }
  }
  .version{
    font-size: 16px;
    color: #2C4262;
  }
  .point{
    background: #A4A4A4;
    width: 5px;
    height: 5px;
    margin-right: 8px;
  }
  .icon-version{
    background: url('../../../../assets/icons/icon-minip-version.png') no-repeat center center/100% 100%;
    width: 18px;
    height: 18px;
    margin-right: 10px;
  }
  .audit-status0{
    width: 88px;
    height: 24px;
    line-height: 24px;
    background: #EBF8F5;
    border: 1px solid #D2ECE6;
    border-radius: 12px;
    color: #259E82;
    font-size: 13px;
    text-align: center;
  }
  .audit-status1{
    width: 88px;
    height: 24px;
    line-height: 24px;
    background: #FAF5F5;
    border: 1px solid #F0E4E4;
    border-radius: 12px;
    color: #9C5757;
    font-size: 13px;
    text-align: center;
  }
  .audit-status2{
    width: 88px;
    height: 24px;
    line-height: 24px;
    background: #E9F0FD;
    border: 1px solid #DFE7F5;
    border-radius: 12px;
    color: #556990;
    font-size: 13px;
    text-align: center;
  }
  .audit-status3{
    width: 88px;
    height: 24px;
    line-height: 24px;
    background: #F5F5F5;
    border: 1px solid #F1F1F1;
    border-radius: 12px;
    color: #A4A4A4;
    font-size: 13px;
    text-align: center;
  }
}
</style>