<template>
  <el-dialog
    title="选择回退版本"
    :visible.sync="visible"
    width="800px"
    append-to-body
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="hideDialog">
    <div>
      <el-table :data="templateList" style="width: 100%" border header-row-class-name="table-header" highlight-current-row @current-change="changeRow">
        <el-table-column align="center" width="60">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.checked"></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="appVersion" label="小程序版本" width="120"></el-table-column>
        <el-table-column align="center" prop="userVersion" label="模板版本号" width="120"></el-table-column>
        <el-table-column prop="userDesc" label="模板描述"></el-table-column>
        <el-table-column align="center" prop="timeFmt" label="更新时间" width="170"></el-table-column>
      </el-table>
    </div>
    <div slot="footer">
      <el-button size="small" @click="hideDialog">取消</el-button>
      <el-button type="primary" size="small" @click="confirm" :disabled="loading || !chooseVersion">
        确认回退
        <i class="el-icon-loading" v-show="loading"></i>
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { get_history_release, back_release } from '@/api/miniprogram'
import moment from 'moment'
export default {
  props: {
    visible: {
      type: Boolean
    },
    id: {
      type: String
    }
  },
  data() {
    return {
      loading: false,
      templateList: [],
      chooseVersion: ''
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.chooseVersion = ''
        this.templateList = []
        this.getList()
      }
    }
  },
  methods: {
    hideDialog() {
      this.$emit('update:visible', false)
    },
    getList() {
      get_history_release({
        id: this.id
      }).then(res => {
        this.templateList = (res || []).map(o => {
          return {
            ...o,
            timeFmt: moment.unix(o.commitTime).format('YYYY-MM-DD HH:mm:ss')
          }
        })
      })
    },
    changeRow(currentRow, oldCurrentRow) {
      currentRow.checked = true
      if (oldCurrentRow) oldCurrentRow.checked = false
      this.chooseVersion = currentRow.appVersion
    },
    confirm() {
      this.loading = true
      back_release({
        id: this.id,
        appVersion: this.chooseVersion
      }).then(() => {
        this.loading = false
        this.$message.success('回退成功')
        this.$emit('reload')
        this.hideDialog()
      }).catch(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style>

</style>