<template>
  <el-dialog
    title="绑定体验成员"
    :visible.sync="visible"
    width="400px"
    append-to-body
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="hideDialog">
    <div>
      <div class="form-item flex flex-center">
        <span class="form-label">微信号</span>
        <el-input class="flex-1" v-model="wechatId" size="small"></el-input>
      </div>
    </div>
    <div slot="footer">
      <el-button size="small" @click="hideDialog">取消</el-button>
      <el-button type="primary" size="small" @click="confirm" :disabled="loading || !wechatId">
        确认
        <i class="el-icon-loading" v-show="loading"></i>
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { bind_tester } from '@/api/miniprogram'
export default {
  props: {
    visible: {
      type: Boolean
    },
    id: {
      type: String
    }
  },
  data() {
    return {
      loading: false,
      wechatId: ''
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.wechatId = ''
      }
    }
  },
  methods: {
    hideDialog() {
      this.$emit('update:visible', false)
    },
    confirm() {
      bind_tester({
        id: this.id,
        wechatId: this.wechatId
      }).then(() => {
        this.$message.success('绑定成功')
        this.loading = false
        this.hideDialog()
      }).catch(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.form-item{
  padding-left: 7px;
  margin-bottom: 16px;
  .must{
    color: #E14B38
  }
  .form-label{
    // width: 80px;
    text-align: right;
    padding-right: 20px;
  }
}
</style>