<template>
  <el-dialog
    title="拒绝原因"
    :visible.sync="visible"
    width="600px"
    append-to-body
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="hideDialog">
    <div>
      <div class="mb-20" v-html="reason">{{reason}}</div>
      <div>
        <el-image
          v-for="(item, index) in screenShots"
          :key="index"
          style="width: 100px; height: 100px"
          :src="item" 
          :preview-src-list="screenShots">
        </el-image>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean
    },
    reason: {
      type: String
    },
    screenShots: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
    }
  },
  methods: {
    hideDialog() {
      this.$emit('update:visible', false)
    },
  }
}
</script>

<style lang="scss" scoped>
.form-item{
  padding-left: 7px;
  margin-bottom: 16px;
  .must{
    color: #E14B38
  }
  .form-label{
    // width: 80px;
    text-align: right;
    padding-right: 20px;
  }
}
</style>