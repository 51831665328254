<template>
  <el-dialog
    title="选择代码模板"
    :visible.sync="visible"
    width="800px"
    append-to-body
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="hideDialog">
    <div>
      <el-table :data="templateList" style="width: 100%" border header-row-class-name="table-header" highlight-current-row @current-change="changeRow">
        <el-table-column align="center" width="60">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.checked"></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="templateId" label="模板ID" width="80"></el-table-column>
        <el-table-column align="center" prop="userVersion" label="版本号" width="120"></el-table-column>
        <el-table-column prop="userDesc" label="版本描述"></el-table-column>
        <el-table-column align="center" prop="timeFmt" label="被添加为模板时间" width="170"></el-table-column>
      </el-table>
    </div>
    <div slot="footer">
      <el-button size="small" @click="hideDialog">取消</el-button>
      <el-button type="primary" size="small" @click="confirm" :disabled="loading || !chooseItem">
        确认上传
        <i class="el-icon-loading" v-show="loading"></i>
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { get_template_list, commit_minip } from '@/api/miniprogram'
import moment from 'moment'
export default {
  props: {
    visible: {
      type: Boolean
    },
    id: {
      type: String
    },
    type: {
      type: String
    }
  },
  data() {
    return {
      loading: false,
      templateList: [],
      chooseItem: null
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.chooseItem = null
        this.templateList = []
        this.getList()
      }
    }
  },
  methods: {
    hideDialog() {
      this.$emit('update:visible', false)
    },
    getList() {
      get_template_list({
        templateType: 0,
        sourceMiniprogramAppid: this.type == 'normal'?'wx155132dc5d7a83b2':'wxaae7fbbc14c441c8'
      }).then(res => {
        this.templateList = (res || []).map(o => {
          return {
            ...o,
            timeFmt: moment.unix(o.createTime).format('YYYY-MM-DD HH:mm:ss')
          }
        })
        this.templateList.sort((a, b) => {
          return b.createTime - a.createTime
        })
      })
    },
    changeRow(currentRow, oldCurrentRow) {
      currentRow.checked = true
      if (oldCurrentRow) oldCurrentRow.checked = false
      this.chooseItem = currentRow
    },
    confirm() {
      this.loading = true
      commit_minip({
        id: this.id,
        templateId: this.chooseItem.templateId,
        userVersion: this.chooseItem.userVersion,
        userDesc: this.chooseItem.userDesc,
        extJson: '{}'
      }).then(() => {
        this.loading = false
        this.$message.success('上传成功')
        this.$emit('reload')
        this.hideDialog()
      }).catch(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style>

</style>