<template>
  <el-dialog
    title="选择门店"
    :visible.sync="visible"
    width="600px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="hideDialog">
    <div slot="title">
      选择门店
      <span class="c-a4 font-size-12">（已选{{selected.length}}家）</span>
    </div>
    <div class='box'>
      <div class="search flex flex-align-center">
        <el-input class="mr-10" v-model="searchHotel" size="small" clearable placeholder="请输入门店名称搜索"></el-input>
        <el-button style="width: 100px" type="primary" size="small" placeholder="请输入">查询</el-button>
      </div>
      <div class="mt-20">
        <div class="item flex flex-align-center flex-between" @click="chooseAll">
          <div>
            <span class="c-1" :class="checkedAll?'bb-1':''">门店名称</span>
          </div>
          <span :class="checkedAll?'icon-check':'icon-uncheck'"></span>
        </div>
      </div>
      <div class="list" v-if="showlist.length > 0">
        <div class="item flex flex-align-center flex-between" v-for="(o,i) in showlist" :key="i" @click="choose(o)">
          <div>
            <span class="c-1" :class="o.checked?'bb-1':''">{{o.hotelShortName || o.hotelName}}</span>
          </div>
          <span :class="o.checked?'icon-check':'icon-uncheck'"></span>
        </div>
      </div>
      <div class="list mt-20" v-else>
        <el-empty :image="require('../../../../assets/icons/empty.png')" description="暂无搜索结果"></el-empty>
      </div>
    </div>
    <div slot="footer">
      <el-button size="small" @click="hideDialog">取消</el-button>
      <el-button type="primary" size="small" @click="confirm">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: '',
  props: {
    visible: {
      type: Boolean
    },
    relationHotelInfoList: {
      type: Array,
      default: function() {
        return []
      }
    },
    minipHotelList: {
      type: Array,
      default: function() {
        return []
      }
    }
  },
  data() {
    return {
      searchHotel: '',
      selected: [],
      checkedAll: false
    };
  },
  watch: {
    visible(val) {
      if (val) {
        this.selected = this.relationHotelInfoList.concat()
      } else {
        this.selected = []
        this.searchHotel = ''
      }
    },
  },
  computed: {
    list() {
      return this.minipHotelList.map(o => {
        return {
          ...o,
          checked: this.selected.findIndex(v => { return o.id == v.id }) > -1
        }
      })
    },
    showlist() {
      return this.list.filter(o => {
        return o.hotelName.indexOf(this.searchHotel) > -1 || o.hotelShortName.indexOf(this.searchHotel) > -1
      })
    }
  },
  methods: {
    chooseAll() {
      this.checkedAll = !this.checkedAll
      if (this.checkedAll) {
        this.selected = this.list.concat()
      } else {
        this.selected = []
      }
    },
    choose(val) {
      val.checked = !val.checked
      this.selected = this.list.filter(o => { return o.checked })
      this.$forceUpdate()
    },
    confirm() {
      console.log('选中门店', this.selected)
      this.$emit('update:relationHotelInfoList', this.selected)
      this.hideDialog()
    },
    hideDialog() {
      this.$emit('update:visible', false)
    },
  },
};
</script>

<style scoped lang='scss'>
.list{
  max-height: 400px;
  overflow: auto;
}
.item {
  border-bottom: 1px solid #f1f1f1;
  // border-radius: 5px;
  padding: 14px 10px;
  margin-bottom: 10px;
  .label {
    // background: #5390FF;
    background: rgba(83,144,255,0.1);
    // opacity: 0.1;
    color: #5181D9;
    padding: 4px 10px;
    margin-right: 10px;
    border-radius: 5px;
    font-size: 13px;
  }
  .label2 {
    // background: #5390FF;
    background: #FBF2DF;
    // opacity: 0.1;
    color: #C9920D;
    padding: 4px 10px;
    margin-right: 10px;
    border-radius: 5px;
    font-size: 13px;
  }
}
.bb-1 {
  // border: 1px solid #5390FF;
  // font-weight: bold;
  color: #212121!important;
}
.c-a4 {
  color: #a4a4a4;
}
.c-theme {
  color: #5390FF;
}
.c-1 {
  color: #666666;
}
.icon-check {
  width: 16px;
  height: 16px;
  display: inline-block;
  background: url('../../../../assets/icons/check.png') no-repeat center center/100% 100%;
}
.icon-uncheck {
  width: 16px;
  height: 16px;
  display: inline-block;
  background: url('../../../../assets/icons/uncheck.png') no-repeat center center/100% 100%;
}
</style>
