<template>
  <el-dialog
    title="已选门店"
    :visible.sync="visible"
    width="600px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="hideDialog">
    <div>
      <div class="relation-item flex flex-center" v-for="(item, index) in selected" :key="item.id">
        <span class="flex-1">{{item.hotelShortName || item.hotelName}}</span>
        <i class="font-size-16 el-icon-error c-a4 cursor-point" @click="deleteItem(index)"></i>
      </div>
    </div>
    <div slot="footer">
      <el-button size="small" @click="hideDialog">取消</el-button>
      <el-button type="primary" size="small" @click="confirm">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: '',
  props: {
    visible: {
      type: Boolean
    },
    relationHotelInfoList: {
      type: Array,
      default: function() {
        return []
      }
    },
  },
  data() {
    return {
      selected: []
    };
  },
  watch: {
    visible(val) {
      if (val) {
        this.selected = this.relationHotelInfoList.concat()
      } else {
        this.selected = []
      }
    },
  },
  computed: {
  },
  methods: {
    deleteItem(index) {
      this.selected.splice(index, 1)
      this.$forceUpdate()
    },
    confirm() {
      console.log('选中门店', this.selected)
      this.$emit('update:relationHotelInfoList', this.selected)
      this.hideDialog()
    },
    hideDialog() {
      this.$emit('update:visible', false)
    },
  },
};
</script>

<style scoped lang='scss'>
.c-a4 {
  color: #a4a4a4;
}
.relation-item{
  width: 100%;
  background: #F5F8FD;
  height: 32px;
  line-height: 32px;
  padding: 0 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
}
</style>
