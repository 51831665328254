import { ajax } from "@/api/request";

// 小程序列表
export const get_minip_list = function(data) {
  return ajax({
    method: 'post',
    url: '/miniProgram/v1/getMiniProgramList',
    data
  });
}

// 小程序删除
export const delete_minip = function(data) {
  return ajax({
    method: 'post',
    url: '/clearData/v1/clearMp',
    params: data
  });
}

// 小程序业务数据清除
export const clear_minip_data = function(data) {
  return ajax({
    method: 'post',
    url: '/clearData/v1/clearMpBizData',
    params: data
  });
}

// 小程序信息
export const get_minip_detail = function(params) {
  return ajax({
    method: 'get',
    url: '/miniProgram/v1/getMiniProgramOpenInfo',
    params
  });
}

// 小程序新增
export const add_minip = function(data) {
  return ajax({
    method: 'post',
    url: '/miniProgram/v1/saveMiniProgram',
    data,
    timeout: 10000000
  });
}

// 小程序编辑
export const edit_minip = function(data) {
  return ajax({
    method: 'post',
    url: '/miniProgram/v1/updateMiniProgram',
    data
  });
}

// 小程序编辑校验
export const edit_minipPre = function(data) {
  return ajax({
    method: 'post',
    url: '/miniProgram/v1/updateMiniProgramPre',
    data
  });
}

// 小程序所属机构下拉数据
export const get_minip_org_list = function(data) {
  return ajax({
    method: 'post',
    url: '/miniProgram/v1/getSaveMiniProgramOrgList',
    data
  });
}

// 小程序关联门店下拉数据
export const get_minip_hotel_list = function(params) {
  return ajax({
    method: 'get',
    url: '/miniProgram/v1/getSaveMiniProgramRelationHotelList',
    params
  });
}

// 小程序状态修改
export const update_minip_status = function(data) {
  return ajax({
    method: 'post',
    url: '/miniProgram/v1/updateMiniProgramStatus',
    data
  });
}

// 小程序续期
export const renew_minip = function(data) {
  return ajax({
    method: 'post',
    url: '/miniProgram/v1/miniProgramRenew',
    data
  });
}

// 小程序延期
export const delay_minip = function(data) {
  return ajax({
    method: 'post',
    url: '/miniProgram/v1/miniProgramDelay',
    data
  });
}

// 小程序续期延期记录
export const minip_renew_log = function(data) {
  return ajax({
    method: 'post',
    url: '/miniProgram/v1/miniProgramRenewList',
    data
  });
}

// 小程序授权信息
export const get_minip_auth_and_version = function(params) {
  return ajax({
    method: 'get',
    url: '/miniProgram/v1/getMiniProgramAuthorizeAndVersionInfo',
    params
  });
}

// 设置小程序域名
export const update_minip_domain = function(data) {
  return ajax({
    method: 'post',
    url: '/miniProgram/v1/modify/domain',
    data
  });
}

// 小程序历史版本
export const get_history_release = function(params) {
  return ajax({
    method: 'get',
    url: '/miniProgram/v1/getHistoryRelease',
    params
  });
}

// 小程序回退版本
export const back_release = function(params) {
  return ajax({
    method: 'get',
    url: '/miniProgram/v1/backRelease',
    params
  });
}

// 小程序审核撤回
export const undo_code_audit = function(params) {
  return ajax({
    method: 'get',
    url: '/miniProgram/v1/undoCodeAudit',
    params
  });
}

// 小程序发布
export const release_minip = function(params) {
  return ajax({
    method: 'get',
    url: '/miniProgram/v1/release',
    params
  });
}

// 小程序类别
export const get_category = function(params) {
  return ajax({
    method: 'get',
    url: '/miniProgram/v1/getCategory',
    params
  });
}

// 小程序提审
export const submit_audit = function(data) {
  return ajax({
    method: 'post',
    url: '/miniProgram/v1/submitAudit',
    data
  });
}

// 小程序上传
export const commit_minip = function(data) {
  return ajax({
    method: 'post',
    url: '/miniProgram/v1/commit',
    data
  });
}

// 小程序草稿箱
export const get_draft_list = function(params) {
  return ajax({
    method: 'get',
    url: '/miniProgram/v1/getTemplateDraftList',
    params
  });
}

// 小程序代码模板库
export const get_template_list = function(data) {
  return ajax({
    method: 'post',
    url: '/miniProgram/v1/getTemplateList',
    params: data
  });
}

// 小程序草稿添加到模板库
export const add_to_template = function(data) {
  return ajax({
    method: 'post',
    url: '/miniProgram/v1/addToTemplate',
    data
  });
}

// 小程序删除模板库
export const delete_template = function(data) {
  return ajax({
    method: 'post',
    url: '/miniProgram/v1/deleteTemplate',
    params: data
  });
}

// 小程序绑定体验者
export const bind_tester = function(data) {
  return ajax({
    method: 'post',
    url: '/miniProgram/v1/bindTester',
    data
  });
}

// 小程序解绑体验者
export const unbind_tester = function(data) {
  return ajax({
    method: 'post',
    url: '/miniProgram/v1/unbindTester',
    data
  });
}

// 小程序体验者列表
export const tester_list = function(data) {
  return ajax({
    method: 'post',
    url: '/miniProgram/v1/memberAuth',
    data
  });
}
